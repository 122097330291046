<template>
	<v-app app id="loading-menu">
		<v-container align="center" justify="center" fill-height fluid>
			<v-row align="center" justify ="center" >  
				<v-progress-circular indeterminate color="white" size="100" width="7"/>
			</v-row>
		</v-container>
	</v-app>
</template>

<script>

export default {
	props:["userCreds"],
	
	created(){
		var self = this;
		// get the user from local storage and check that they are still logged in to provider
		this.mgr.getUser().then( (user) => {
			if(user === null) {
				this.mgr.signinRedirect()
			}
			else {
				this.mgr.querySessionStatus().then( () => {
					if( user.profile.role.toLowerCase() != 'prisoner') {
						self.$router.push( { name: 'denied', params: {userCreds: user} } );
					}
					else{
						if("Theme" in user.profile) {
							this.$setTheme = user.profile.Theme;
						}
						this.ApiService.setDefaultToken(user.access_token);			
						self.$router.push( { name: 'home', params: {userCreds: user} } );
					}

				}).catch((e) => {
					if(e.message === "login_required") {
						this.mgr.signinRedirect()
					}
					else {
						throw(e.message) // 'Frame window timed out' or some other error
					}
				});
			}
		});
	},
}

</script>